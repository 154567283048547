<template>
	<div class="pb-5" v-if="active">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'PrinorWizard-Content',
	data() {
		return {
			active: false,
		};
	},
};
</script>

<style scoped></style>
