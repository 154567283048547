<template>
	<div>
		<div class="wizard-step" data-wizard-state="current" v-if="active" v-on:click="setIndex">
			<div class="wizard-wrapper">
				<div class="wizard-icon">
					<i :class="icon"></i>
				</div>
				<div class="wizard-label">
					<h3 class="wizard-title">{{ title }}</h3>
					<div class="wizard-desc">{{ description }}</div>
				</div>
			</div>
		</div>
		<div class="wizard-step" v-if="!active" v-on:click="setIndex">
			<div class="wizard-wrapper">
				<div class="wizard-icon">
					<i :class="icon"></i>
				</div>
				<div class="wizard-label">
					<h3 class="wizard-title">{{ title }}</h3>
					<div class="wizard-desc">{{ description }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PrinorWizard-Nav',
	props: {
		icon: String,
		title: String,
		description: String,
	},
	data() {
		return {
			active: false,
			index: -1,
		};
	},
	methods: {
		setIndex: function() {
			this.$parent.$emit('setIndex', this.index);
		},
	},
};
</script>

<style scoped>
.wizard-icon > i {
	font-size: 100%;
}
</style>
