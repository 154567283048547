<template>
	<form class="form" @submit.stop.prevent="onSubmit">
		<PrinorWizard>
			<template v-slot:nav>
				<PrinorWizardNav :title="$t('GENERAL.General.general')" :description="$t('VIEWS.Administration.Company.General.description')" icon="far fa-building"></PrinorWizardNav>
				<PrinorWizardNav :title="$t('VIEWS.Administration.Company.Address.address')" :description="$t('VIEWS.Administration.Company.Address.address')" icon="far fa-compass"></PrinorWizardNav>
				<PrinorWizardNav :title="$t('VIEWS.Administration.Company.MailServer.mailServer')" :description="$t('VIEWS.Administration.Company.MailServer.description')" icon="far fa-envelope"></PrinorWizardNav>
<!--				<PrinorWizardNav :title="$t('VIEWS.Administration.Company.Modules.title')" :description="$t('VIEWS.Administration.Company.Modules.description')" icon="far fa-envelope"></PrinorWizardNav>-->
			</template>

			<template v-slot:content>
				<PrinorWizardContent>
					<h4 class="mb-10 font-weight-bold text-dark">{{ $t('VIEWS.Administration.Company.General.title') }}</h4>
					<div class="row">
						<div class="col-12">
							<div class="form-group">
								<label>{{ $t('GENERAL.Forms.name') }}</label>
								<input type="text" class="form-control" v-model="form.name" name="name" />
							</div>
						</div>
						<div class="col-12">
							<div class="form-group">
								<label for="description">{{ $t('GENERAL.Forms.description') }}</label>
								<textarea id="description" name="description" class="form-control" rows="5" v-model="form.description"></textarea>
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label for="industry">{{ $t('VIEWS.Administration.Company.General.industry') }}</label>
								<select class="form-control" id="industry" name="industry" v-model="form.branch_id">
									<option value=""></option>
									<template v-if="!!branches && branches.length > 0">
										<option v-for="industry in branches" :value="industry.id" :key="industry.id">
											{{ industry.name }}
										</option>
									</template>
								</select>
							</div>
						</div>
						<div class="col-12 col-md-8">
							<div class="form-group">
								<label for="notification_mail">{{ $t('VIEWS.Administration.Company.General.notificationEmail') }}</label>
								<input type="text" class="form-control" id="notification_mail" name="notification_mail" v-model="form.notification_mail" />
							</div>
						</div>
					</div>
				</PrinorWizardContent>

				<PrinorWizardContent>
					<h4 class="mb-10 font-weight-bold text-dark">{{ $t('VIEWS.Administration.Company.Address.title') }}</h4>
					<div class="row">
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label>{{ $t('VIEWS.Administration.Company.Address.street') }}</label>
								<input type="text" class="form-control" v-model="form.street" name="street" />
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label>{{ $t('VIEWS.Administration.Company.Address.postcode') }}</label>
								<input type="text" class="form-control" v-model="form.zip_code" name="zip_code" />
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label>{{ $t('VIEWS.Administration.Company.Address.town') }}</label>
								<input type="text" class="form-control" v-model="form.town" name="town" />
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label for="district">{{ $t('VIEWS.Administration.Company.Address.district') }}</label>
								<select class="form-control" id="district" name="district" v-model="form.district_id">
									<option value=""></option>
									<template v-if="!!districts && districts.length > 0">
										<option v-for="district in districts" :value="district.id" :key="district.id">
											{{ district.name }}
										</option>
									</template>
								</select>
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label for="state">{{ $t('VIEWS.Administration.Company.Address.state') }}</label>
								<select class="form-control" id="state" name="state" v-model="form.state_id">
									<option value=""></option>
									<template v-if="!!states && states.length > 0">
										<option v-for="state in states" :value="state.id" :key="state.id">{{ state.name }} </option>
									</template>
								</select>
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label for="country">{{ $t('VIEWS.Administration.Company.Address.country') }}</label>
								<select class="form-control" id="country" name="country" v-model="form.country_id">
									<option value=""></option>
									<template v-if="!!countries && countries.length > 0">
										<option v-for="country in countries" :value="country.id" :key="country.id">
											{{ country.name }}
										</option>
									</template>
								</select>
							</div>
						</div>
					</div>
				</PrinorWizardContent>

				<PrinorWizardContent>
					<h4 class="mb-10 font-weight-bold text-dark">{{ $t('VIEWS.Administration.Company.MailServer.title') }}</h4>
					<div class="row">
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label>{{ $t('VIEWS.Administration.Company.MailServer.smtpHost') }}</label>
								<input type="text" class="form-control" v-model="form.smtp_host" name="smtp_host" />
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label>{{ $t('VIEWS.Administration.Company.MailServer.smtpUser') }}</label>
								<input type="text" class="form-control" v-model="form.smtp_user" name="smtp_user" />
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label>{{ $t('VIEWS.Administration.Company.MailServer.smtpPassword') }}</label>
								<input type="text" class="form-control" v-model="form.smtp_password" name="smtp_password" />
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label>{{ $t('VIEWS.Administration.Company.MailServer.smtpFrom') }}</label>
								<input type="text" class="form-control" v-model="form.smtp_from" name="smtp_from" />
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label>{{ $t('VIEWS.Administration.Company.MailServer.smtpPort') }}</label>
								<input type="number" class="form-control" v-model="form.smtp_port" name="smtp_port" />
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label>{{ $t('VIEWS.Administration.Company.MailServer.smtpEncryption') }}</label>
								<select class="form-control" id="smtp_encryption" name="smtp_encryption" v-model="form.smtp_encryption">
									<option value="">{{ $t('GENERAL.General.no') }}</option>
									<option value="ssl">{{ $t('GENERAL.General.yes') }}</option>
								</select>
							</div>
						</div>
					</div>
				</PrinorWizardContent>

<!--				<PrinorWizardContent>-->
<!--					<h4 class="mb-10 font-weight-bold text-dark">{{ $t('VIEWS.Administration.Company.MailServer.title') }}</h4>-->
<!--					<div class="accordion">-->
<!--						<b-card no-body class="mb-1" v-if="('catch' in form.configuration)">-->
<!--							<b-card-header header-tag="header" class="p-1" role="tab">-->
<!--								<b-button block v-b-toggle.accordion-1 variant="primary">CATCH (Prof4Net)</b-button>-->
<!--							</b-card-header>-->
<!--							<b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">-->
<!--								<b-card-body>-->
<!--									<div class="row">-->
<!--										<div class="col-12">-->
<!--											<div class="form-group">-->
<!--												<label>Test</label>-->
<!--												<input type="text" class="form-control"/>-->
<!--											</div>-->
<!--										</div>-->
<!--									</div>-->
<!--								</b-card-body>-->
<!--							</b-collapse>-->
<!--						</b-card>-->
<!--					</div>-->
<!--				</PrinorWizardContent>-->
			</template>
		</PrinorWizard>
	</form>
</template>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-2.scss';
</style>
<script>
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import PrinorWizard from '@/view/component/misc/Wizard/Wizard';
import PrinorWizardNav from '@/view/component/misc/Wizard/WizardNav';
import PrinorWizardContent from '@/view/component/misc/Wizard/WizardContent';
// import { LOGOUT } from '@/core/services/store/auth.module';
import Swal from 'sweetalert2';
import { PRINOR_TOASTS } from '@/core/services/toast.service';
import { mapGetters } from 'vuex';
import { PRINOR_ACTIONS, PRINOR_PROCESSES } from '@/core/services/actions.service';
import { PRINOR_PERMISSION } from '@/core/services/permissions.service';
import { SET_ACTION } from '@/core/services/store/actions.module';

export default {
	name: 'Organisation',
	components: { PrinorWizardContent, PrinorWizardNav, PrinorWizard },
	data() {
		return {
			companyId: '',
			form: {
				name: '',
				description: '',
				branch_id: '',
				country_id: '',
				district_id: '',
				smtp_encryption: '',
				smtp_from: '',
				smtp_host: '',
				smtp_password: '',
				smtp_port: '',
				smtp_user: '',
				state_id: '',
				street: '',
				town: '',
				zip_code: '',
				notification_mail: '',
				configuration: {},
			},
			branches: [],
			countries: [],
			districts: [],
			states: [],
			saveButtonId: '',
			deleteButtonId: '',
		};
	},
	mounted() {
		this.$store.dispatch(SET_ACTION, []);
		this.$store.dispatch(SET_BREADCRUMB, [
			{
				title: this.$t('MENU.Administration.Administration'),
				route: '/administration',
			},
			{ title: this.$t('MENU.Administration.Company') },
		]);

		this.saveButtonId = PRINOR_ACTIONS.create(this, this.$t('GENERAL.Forms.save'), this.onSubmit, 'success', '', false, !this.hasPermission('action.company.save'));
		this.deleteButtonId = PRINOR_ACTIONS.create(this, this.$t('GENERAL.Forms.delete'), this.onDelete, 'danger', 'fas fa-exclamation-triangle', false, !this.hasPermission('action.company.delete'));

		PRINOR_PROCESSES.isLoading();

		this.get().then(data => {
			if (data.success === true) {
				Object.keys(this.form).forEach(key => {
					this.form[key] = data.data.company[key];
				});

				this.companyId = data.data.company.id;
				this.branches = Object.values(data.data.branches);
				this.countries = Object.values(data.data.countries);
				this.districts = Object.values(data.data.districts);
				this.states = Object.values(data.data.states);
			}

			PRINOR_PROCESSES.reset();
		});
	},
	computed: {
		...mapGetters(['pageProcesses']),
	},
	methods: {
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
		onSubmit() {
			PRINOR_PROCESSES.isUpdating();
			this.update();
		},
		onDelete() {
			let that = this;
			Swal.fire({
				title: this.$t('VIEWS.Administration.Company.deleteTitle'),
				text: this.$t('VIEWS.Administration.Company.deleteConfirmation'),
				icon: 'warning',
				confirmButtonText: this.$t('GENERAL.Forms.delete'),
				showCancelButton: true,
				cancelButtonText: this.$t('GENERAL.Buttons.cancel'),
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary',
				},
				heightAuto: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			}).then(result => {
				if (result.isConfirmed) {
					PRINOR_PROCESSES.isDeleting();
					that.delete().then(data => {
						if (data.success === true) {
							// this.$store.dispatch(LOGOUT).then(() => this.$router.push({ name: 'login' }));
						}
					});
				}
			});
		},
		get() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('company').then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						resolve(data.data);
					}
				});
			});
		},
		update() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.put('company/' + this.companyId, this.form).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							PRINOR_TOASTS.save.confirmation(this);
						} else {
							PRINOR_TOASTS.save.error(this, data.data.error);
						}
						resolve(data.data);
					}
				});
			});
		},
		delete() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.delete('company/' + this.companyId).then(data => {
					PRINOR_PROCESSES.reset();
					resolve(data.data);
				});
			});
		},
	},
	watch: {
		pageProcesses: {
			deep: true,
			handler: function(newVal) {
				PRINOR_ACTIONS.setProcessing(this, this.saveButtonId, newVal.isUpdating);
				PRINOR_ACTIONS.setProcessing(this, this.deleteButtonId, newVal.isDeleting);
			},
		},
	},
};
</script>
