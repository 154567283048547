<template>
	<div class="card card-custom">
		<div class="card-body p-0">
			<!--begin: Wizard-->
			<div class="wizard wizard-2" id="kt_wizard_v2" data-wizard-state="step-first" data-wizard-clickable="true">
				<!--begin: Wizard Nav -->
				<div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
					<div class="wizard-steps">
						<slot name="nav"></slot>
					</div>
				</div>
				<!--end: Wizard Nav -->

				<!--begin: Wizard Body -->
				<div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
					<!--begin: Wizard Form-->
					<div class="row">
						<div class="offset-xxl-2 col-xxl-8">
							<slot name="content"></slot>
							<slot name="action"></slot>
						</div>
						<!--end: Wizard-->
					</div>
				</div>
				<!--end: Wizard Body -->
			</div>
			<!--end: Wizard-->
		</div>
	</div>
</template>

<script>
export default {
	name: 'PrinorWizard',
	data() {
		return {
			navItems: [],
			contentItems: [],
			activeIndex: 0,
		};
	},
	mounted() {
		let that = this;
		this.$on('setIndex', this.setIndex);

		let navIndex = 0;
		let contentIndex = 0;
		if (this.$children.length > 0) {
			this.$children.forEach(child => {
				if (child.$options.name === 'PrinorWizard-Nav') {
					child.index = navIndex;
					that.navItems.push(child);
					navIndex++;
				} else if (child.$options.name === 'PrinorWizard-Content') {
					child.index = contentIndex;
					that.contentItems.push(child);
					contentIndex++;
				}
			});

			this.activateIndex();
		}
	},
	methods: {
		setIndex: function(index) {
			if (index >= 0 && index < this.contentItems.length) {
				this.activeIndex = index;
				this.activateIndex();
			}
		},
		activateIndex: function() {
			this.navItems.forEach(item => {
				item.active = false;
			});
			this.contentItems.forEach(item => {
				item.active = false;
			});

			this.navItems[this.activeIndex].active = true;
			this.contentItems[this.activeIndex].active = true;
		},
	},
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-2.scss';
</style>
